import { Theme, css } from '@emotion/react';

export const headerGlobalNavigationListWrapper = (itemCount: number) => css`
	width: calc(100% / ${itemCount});
	text-align: center;
`;

export const headerGlobalNavigationListItem = (
	theme: Theme,
	isActive: boolean,
) => css`
	position: relative;
	display: inline-block;
	line-height: 1;
	padding: 24px 0;
	&:hover {
		color: ${theme.users.colors.primary};
		&::after {
			opacity: 1;
		}
	}
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		display: block;
		width: 100%;
		height: 3px;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
		background-color: ${theme.users.colors.primary};
		opacity: 0;
	}
	${isActive &&
	`
    color: ${theme.users.colors.primary};
    &::after {
			opacity: 1;
		}
  `}
`;
