import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const headerWrapper = (theme: Theme) => css`
	background-color: ${theme.users.colors.background.default};
	padding: ${theme.users.spacing.desktop.headerInnerVertical} 0;
	border-bottom: 3px solid ${theme.users.colors.primary};
	${maxScreen('desktop')} {
		border-bottom: 2px solid ${theme.users.colors.primary};
		padding: 18px 4vw;
	}
`;

export const headerInner = (theme: Theme) => css`
	width: ${theme.users.width.desktop.header};
	margin: 0 auto;
	${maxScreen('desktop')} {
		width: 100%;
	}
`;

export const headerTitleWrapper = (theme: Theme) => css`
	display: flex;
	align-items: center;
	${maxScreen('desktop')} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const headerH1 = (theme: Theme) => css`
	font-family: 'M PLUS Rounded 1c', sans-serif;
	font-size: 1.5rem;
	line-height: 1;
	margin-right: 1.5rem;
	${maxScreen('desktop')} {
		margin-bottom: 0.75rem;
	}
	${maxScreen('mobile')} {
		font-size: 5.6vw; // モック準拠
		margin: 0;
		margin-bottom: 12px;
		margin-right: 5px;
		color: ${theme.users.colors.primary};
	}
	&:before {
		content: '';
		display: block;
		width: 55px;
		height: 2.25rem;
		background-image: url(../images/common/icon_ttl_site.svg);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		${maxScreen('mobile')} {
			width: 8.4vw;
			height: 6.4vw;
		}
	}
`;

export const branchNumberText = (theme: Theme) => css`
	font-size: 0.85rem;
	${maxScreen('mobile')} {
		font-size: 3vw; // モック準拠
	}
`;

export const headerRightContainer = (theme: Theme) => css`
	display: flex;
	align-items: center;
	${maxScreen('tablet')} {
		display: none;
	}
`;

export const headerLogoutButton = (theme: Theme) => css`
	font-size: 0.85rem;
	padding: 10px 2rem;
	font-weight: bold;
`;

export const headerLink = (theme: Theme) => css`
	font-size: 0.85rem;
	margin-right: 1rem;
	&:hover {
		text-decoration: underline;
	}
`;
