import { useRouter } from 'next/router';
import { HeaderPresenter } from './presenter';
import { useUserState } from 'hooks/state/useUserState';
import { signout } from 'lib/api/users/signout';
import { INITIAL_USER_STATE } from 'globalStates/atoms/user';
import { toast } from 'react-toastify';

export function Header() {
	const router = useRouter();
	const [user, setUser] = useUserState();

	async function logout() {
		if (await signout(user.token)) {
			setUser(INITIAL_USER_STATE);
			router.push('/login');
			return;
		}
		toast.error('ログアウトに失敗しました');
	}

	return <HeaderPresenter user={user} logout={logout} />;
}
