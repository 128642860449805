import Link from 'next/link';
import { defaultFooterText } from './styles';

export function DefaultFooter() {
	return (
		<div className='w-full absolute bottom-0'>
			<Link href='https://yama-f-market.com/' css={defaultFooterText}>
				© 山口県中央花市場 Co,. Ltd.
			</Link>
		</div>
	);
}
