import { useTheme } from '@emotion/react';
import {
	headerLink,
	headerLinkWrapper,
	logoutButton,
	mobileHeaderGlobalNavigationHambuger,
	mobileHeaderGlobalNavigationInner,
	mobileHeaderGlobalNavigationLink,
	mobileHeaderGlobalNavigationLinkWrapper,
	mobileHeaderGlobalNavigationWrapper,
} from './styles';
import { MobileHeaderGlobalNavigationType } from './types';
import Link from 'next/link';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';

export function MobileHeaderGlobalNavigationPresenter({
	user,
	isVisible,
	setVisible,
	logout,
}: MobileHeaderGlobalNavigationType) {
	const theme = useTheme();

	return (
		<nav css={mobileHeaderGlobalNavigationWrapper}>
			<ul
				css={mobileHeaderGlobalNavigationHambuger(isVisible)}
				onClick={() => setVisible()}
			>
				<li></li>
				<li></li>
				<li></li>
			</ul>
			<div css={mobileHeaderGlobalNavigationInner(theme, isVisible)}>
				<div css={mobileHeaderGlobalNavigationLinkWrapper}>
					<Link css={mobileHeaderGlobalNavigationLink} href='/'>
						ホーム
					</Link>
					<Link css={mobileHeaderGlobalNavigationLink} href='/news'>
						お知らせ
					</Link>
					{user.BUY_PERMIT_FLG && (
						<Link css={mobileHeaderGlobalNavigationLink} href='/web'>
							Web購入
						</Link>
					)}
					{user.BUY_PERMIT_FLG && user.branch_number === '001' && (
						<Link css={mobileHeaderGlobalNavigationLink} href='/business'>
							商談一覧
						</Link>
					)}
					<Link css={mobileHeaderGlobalNavigationLink} href='/sale'>
						お買上げ情報
					</Link>
					{user.BUY_PERMIT_FLG && (
						<Link css={mobileHeaderGlobalNavigationLink} href='/cancellable'>
							取消可能
						</Link>
					)}
					{user.BUY_PERMIT_FLG && (
						<Link
							css={mobileHeaderGlobalNavigationLink}
							href='/next-reservation'
						>
							次回予約
						</Link>
					)}
					<Link css={mobileHeaderGlobalNavigationLink} href='/market'>
						市況
					</Link>
					<Link css={mobileHeaderGlobalNavigationLink} href='/利用規約.pdf'>
						利用規約
					</Link>
					<Link css={mobileHeaderGlobalNavigationLink} href='/om_sp.pdf'>
						マニュアル
					</Link>
					{user.branch_number === '001' && (
						<Link css={mobileHeaderGlobalNavigationLink} href='/setting'>
							設定
						</Link>
					)}
				</div>
				<div css={headerLinkWrapper}>
					{user.is_admin && (
						<Link href='/admins/transaction/sale' css={headerLink}>
							管理者ページ
						</Link>
					)}
					<Link href='mailto:web@yama-f-market.com' css={headerLink}>
						お問い合わせ
					</Link>
					<Link href='/reset-password' css={headerLink}>
						パスワードの再設定
					</Link>
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={logoutButton} onClick={() => logout()}>
						ログアウト
					</SecondaryButton>
				</div>
			</div>
		</nav>
	);
}
