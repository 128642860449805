import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const mobileHeaderGlobalNavigationWrapper = (theme: Theme) => css`
	display: none;
	${maxScreen('mobile')} {
		display: block;
	}
`;

export const mobileHeaderGlobalNavigationInner = (
	theme: Theme,
	isVisible: boolean,
) => css`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${theme.users.colors.background.default};
	z-index: 10;
	visibility: ${isVisible ? 'visible' : 'hidden'};
	opacity: ${isVisible ? 1 : 0};
	transition: all 0.3s ease-out;
	padding: 90px 20px 0;
`;

export const mobileHeaderGlobalNavigationLinkWrapper = (theme: Theme) => css`
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid ${theme.users.colors.border.default};
	padding-bottom: 20px;
`;

export const mobileHeaderGlobalNavigationLink = (theme: Theme) => css`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: 2.8rem;
`;

export const headerLinkWrapper = (theme: Theme) => css`
	display: flex;
	justify-content: center;
	padding: 20px 0;
`;

export const headerLink = (theme: Theme) => css`
	color: ${theme.users.colors.primary};
	padding: 0 0.5rem;
	font-size: 0.85rem;
`;

export const logoutButton = (theme: Theme) => css`
	width: 90%;
`;

export const mobileHeaderGlobalNavigationHambuger = (isVisible: boolean) => css`
	position: relative;
	width: 40px;
	padding: 8px 5px 2px;
	z-index: 11;
	li {
		width: auto;
		height: 2px;
		transition: all 0.2s ease;
		background-color: #111;
		margin: 0 0 7px;
	}
	${isVisible &&
	css`
		position: fixed;
		right: 4vw;
		top: 18px;
		li {
			transform: translatey(8px) rotate(45deg);
		}
		li:nth-child(2) {
			opacity: 0;
		}
		li:nth-child(3) {
			transform: translatey(-10px) rotate(-45deg);
		}
	`}
`;
