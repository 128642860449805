import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const headerGlobalNavigationWrapper = (theme: Theme) => css`
	background-color: ${theme.users.colors.background.default};
	border-bottom: 1px solid ${theme.users.colors.border.default};
	${maxScreen('mobile')} {
		display: none;
	}
`;

export const headerGlobalNavigationInner = (theme: Theme) => css`
	position: relative;
	width: ${theme.users.width.desktop.header};
	margin: 0 auto;
	${maxScreen('desktop')} {
		width: 100%;
	}
`;

export const headerGlobalNavigationManualModal = (theme: Theme) => css`
	position: absolute;
	right: -40px;
	bottom: -105px;
	background-color: ${theme.users.colors.background.default};
	border-radius: 5px;
	box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
	padding: 0.5rem 1rem;
	a {
		display: block;
		line-height: 2.5;
	}
	a:first-child {
		border-bottom: 1px solid ${theme.users.colors.border.default};
	}
`;
