import axios from 'axios';

export async function signout(token: string) {
	try {
		await axios.post(
			`${process.env.API_ENDPOINT}/api/v1/users/signout`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {}
}
