import { useTheme } from '@emotion/react';
import {
	headerGlobalNavigationListItem,
	headerGlobalNavigationListWrapper,
} from './styles';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { HeaderGlobalListItemType } from './types';

export function HeaderGlobalListItem({
	href,
	activePaths,
	label,
	className,
	onClick,
}: HeaderGlobalListItemType) {
	const theme = useTheme();
	const router = useRouter();

	return (
		<li className={className} css={headerGlobalNavigationListWrapper(6)}>
			{href && (
				<Link
					href={href}
					css={headerGlobalNavigationListItem(
						theme,
						activePaths.includes(router.pathname),
					)}
				>
					{label}
				</Link>
			)}
			{onClick && (
				<button
					css={headerGlobalNavigationListItem(
						theme,
						activePaths.includes(router.pathname),
					)}
					onClick={onClick}
				>
					{label}
				</button>
			)}
		</li>
	);
}
