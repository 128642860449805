import {
	branchNumberText,
	headerH1,
	headerInner,
	headerLink,
	headerLogoutButton,
	headerRightContainer,
	headerTitleWrapper,
	headerWrapper,
} from './styles';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import Link from 'next/link';
import { HeaderPresenterType } from './types';
import { MobileHeaderGlobalNavigation } from '../Navigation/MobileHeaderGlobal';

export function HeaderPresenter({ user, logout }: HeaderPresenterType) {
	return (
		<header css={headerWrapper}>
			<div css={headerInner} className='flex items-start justify-between'>
				<div css={headerTitleWrapper}>
					<h1
						css={headerH1}
						className='inline-flex items-center font-bold before:mr-[5px]'
					>
						<Link href='/'>花市場Web販売システム</Link>
					</h1>
					<p css={branchNumberText}>
						{user.baisan_cd}-{user.branch_number}： {user.branch_name}
					</p>
				</div>
				<MobileHeaderGlobalNavigation />
				<div css={headerRightContainer}>
					{user.is_admin && (
						<Link href='/admins/transaction/sale' css={headerLink}>
							管理者ページ
						</Link>
					)}
					<Link href='mailto:web@yama-f-market.com' css={headerLink}>
						お問い合わせ
					</Link>
					<Link href='/reset-password' css={headerLink}>
						パスワードの再設定
					</Link>
					<SecondaryButton css={headerLogoutButton} onClick={logout}>
						ログアウト
					</SecondaryButton>
				</div>
			</div>
		</header>
	);
}
