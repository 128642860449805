import { Theme, css } from '@emotion/react';

export const defaultFooterText = (theme: Theme) => css`
	display: block;
	font-size: 0.8rem;
	text-align: center;
	color: #797979;
	border-top: 1px solid ${theme.users.colors.border.default};
	padding: 25px 0;
	background-color: white;
`;
