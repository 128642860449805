import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const pageH1Wrapper = (theme: Theme) => css`
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 700;
	font-size: 1.6875rem;
	line-height: 1;
	color: ${theme.users.colors.primary};
	padding: calc(${theme.users.spacing.desktop.pageInnerHorizontal} * 2) 0;
	${maxScreen('mobile')} {
		font-size: 6vw; // モック準拠
		padding: 7vw 0;
	}
`;
