import {
	headerGlobalNavigationInner,
	headerGlobalNavigationManualModal,
	headerGlobalNavigationWrapper,
} from './styles';
import useOnClickOutside from 'use-onclickoutside';
import { HeaderGlobalListItem } from 'components/organisms/ListItems/HeaderGlobalListItem';
import { HeaderGlobalNavigationPresenterType } from './types';
import Link from 'next/link';
import { useRef, useState } from 'react';

export function HeaderGlobalNavigationPresenter({
	user,
}: HeaderGlobalNavigationPresenterType) {
	const [isManualVisible, setManualVisible] = useState(false);
	const ref = useRef(null);
	useOnClickOutside(ref, () => setManualVisible(false));

	return (
		<nav css={headerGlobalNavigationWrapper}>
			<ul css={headerGlobalNavigationInner} className='flex'>
				<HeaderGlobalListItem href='/' label='ホーム' activePaths={['/']} />
				<HeaderGlobalListItem
					href='/news'
					label='お知らせ'
					activePaths={['/news', '/news/[id]']}
				/>
				{user.BUY_PERMIT_FLG && (
					<HeaderGlobalListItem
						href='/web'
						label='Web購入'
						activePaths={['/web', '/web/detail', '/web/[id]/buy']}
					/>
				)}
				{user.BUY_PERMIT_FLG && user.branch_number === '001' && (
					<HeaderGlobalListItem
						href='/business'
						label='商談一覧'
						activePaths={['/business']}
					/>
				)}
				<HeaderGlobalListItem
					href='/sale'
					label='お買上げ情報'
					activePaths={['/sale', '/sale/[id]']}
				/>
				{user.BUY_PERMIT_FLG && (
					<HeaderGlobalListItem
						href='/cancellable'
						label='取消可能'
						activePaths={['/cancellable']}
					/>
				)}
				{user.BUY_PERMIT_FLG && (
					<HeaderGlobalListItem
						href='/next-reservation'
						label='次回予約'
						activePaths={['/next-reservation']}
					/>
				)}
				<HeaderGlobalListItem
					href='/market'
					label='市況'
					activePaths={['/market', '/market/detail']}
				/>
				<HeaderGlobalListItem
					href='/利用規約.pdf'
					label='利用規約'
					activePaths={[]}
				/>
				<HeaderGlobalListItem
					label='マニュアル'
					activePaths={[]}
					onClick={() => setManualVisible(true)}
				/>
				{isManualVisible && (
					<div ref={ref} css={headerGlobalNavigationManualModal}>
						<Link href='/om_pc.pdf'>PC版</Link>
						<Link href='/om_sp.pdf'>スマートフォン版</Link>
					</div>
				)}
				{user.branch_number === '001' && (
					<HeaderGlobalListItem
						href='/setting'
						label='設定'
						activePaths={['/setting']}
					/>
				)}
			</ul>
		</nav>
	);
}
